<template>
  <div class="page">
    <el-row>
      <el-col :span="16">
        <el-button type="primary" plain size="small">发送</el-button>
        <el-button type="primary" plain size="small">附件</el-button>
        <el-button type="primary" plain size="small">放弃</el-button>
        <el-button type="primary" plain size="small">保存草稿</el-button>
        <el-button type="primary" plain size="small">选择用户</el-button>
        <el-button type="primary" plain size="small">插入快捷回复</el-button>
        <el-button type="primary" plain size="small">插入模板</el-button>
        <el-button type="primary" plain size="small">插入报价单</el-button>
        <el-button type="primary" plain size="small">插入订单</el-button>
      </el-col>
      <el-col :span="8" style="text-align: right">
        <el-button type="text" size="small">撤销</el-button>
        <el-button type="text" size="small">重做</el-button>
      </el-col>
    </el-row>
      <el-divider></el-divider>
    <el-row >
      <el-form ref="form" label-width="80px" :model="form">
        <el-form-item label="To:">
          <el-input v-model="form.name" size="small" style="width:400px"/>
          <!--  icon="el-icon-search" -->
          <el-button circle size="small" style="margin-left: 10px">cc</el-button>
        </el-form-item>
        <el-form-item label="邮件主题:">
          <el-input v-model="form.to_name" size="small" style="width:500px"/>
        </el-form-item>
        <el-form-item>
          <tinymce v-model="form.descr" placeholder="请输入编辑器" :height="600"/>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row >
      <el-col :span="16">
        <span>监控用户是否已读</span>
        <el-tooltip
            :content="form.is_issue == 1 ? 'ON' : 'OFF'"
            :enterable="false"
            placement="top"
        >
          <el-switch
              v-model="form.is_issue"
              :active-value="1"
              style="margin-left: 10px"
              :inactive-value="0"
          />
        </el-tooltip>
        <el-divider direction="vertical"></el-divider>
        <span>是否Html邮件</span>
        <el-tooltip
            :content="form.is_issue == 1 ? 'ON' : 'OFF'"
            :enterable="false"
            placement="top"
        >
          <el-switch
              v-model="form.is_issue"
              :active-value="1"
              style="margin-left: 10px"
              :inactive-value="0"
          />
        </el-tooltip>
        <el-divider direction="vertical"></el-divider>
        <span>延迟3分钟投递</span>
        <el-tooltip
            :content="form.is_issue == 1 ? 'ON' : 'OFF'"
            :enterable="false"
            placement="top"
        >
          <el-switch
              v-model="form.is_issue"
              :active-value="1"
              style="margin-left: 10px"
              :inactive-value="0"
          />
        </el-tooltip>
        <el-divider direction="vertical"></el-divider>
      </el-col>
      <el-col :span="8">
        <el-button type="primary" style="margin-bottom: 10px">发 送</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {apiGetKeywordList} from "@/request/api";
//import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  name: "redactEmail",
  data() {
    return {
      form: {},
      queryForm: {},
    }
  },
  created() {
    // this.fetchData()
  },
  methods: {
    async fetchData() {
      this.listLoading = true
      apiGetKeywordList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
      this.listLoading = false
    },
  }
}
</script>

<style scoped>
.page{
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}
</style>